import { State } from '../index';
import { createSelector } from '@ngrx/store';
import { UserState } from './user.reducers';
import { LimitType, TariffEnum } from '../../interfaces/user.package';

/* Get user state */
export const userState = (state: State) => state.user;

export const getUserSelector = createSelector(
  userState,
  (state: UserState) => state.user
);

export const getUserInfoSelector = createSelector(
  userState,
  (state: UserState) => state.userInfo
);

export const getUserSubscriptionSelector = createSelector(
  userState,
  (state: UserState) => state.userSubscription
);

export const getIsFreeSelector = createSelector(
  getUserSubscriptionSelector,
  (user) => !!user?.currentPricePlan.free
);

export const getUserProfilesSelector = createSelector(
  userState,
  (state: UserState) => state.profiles
);

export const selectUserPricingInfoSelector = createSelector(
  userState,
  (state: UserState) => state.userPricingInfo
);

export const selectUserPackageSelector = createSelector(
  userState,
  (state: UserState) => state.userPackage
);

export const selectUserPackageAIAvailableSelector = createSelector(
  selectUserPackageSelector,
  (userPackage) =>
    userPackage?.limits.some(
      (limit) => limit.type === LimitType.BOTMAN_AI && limit.available
    ) ?? false
);

export const selectUserPackageAgentSelector = createSelector(
  selectUserPackageSelector,
  (userPackage) => {
    const agentLimit = userPackage?.limits.find(
      (limit) => limit.type === LimitType.AGENT_SEATS
    );

    if (!agentLimit) return false;

    const isProductLimited = userPackage?.productId !== TariffEnum.UNLIMITED;

    return agentLimit.totalSeats <= agentLimit.usedSeats && isProductLimited;
  }
);

export const selectIsFreeOrUnlimitedPackage = createSelector(
  userState,
  (state: UserState) =>
    state.userPackage?.productId === TariffEnum.FREE ||
    state.userPackage?.productId === TariffEnum.UNLIMITED
);

export const selectUserPackageIsFreeSelector = createSelector(
  selectUserPackageSelector,
  (userPackage) => userPackage?.productId === TariffEnum.FREE
);
