import { createSelector } from '@ngrx/store';
import { AppState } from './app.reducers';
import { State } from '../index';
import { ChannelTypeEnum } from '../../data/channel-types';
import { IChannel } from '../../interfaces/channel.interface';
import { Flow } from '../../interfaces/bot.interface';
import { Tag } from '../../interfaces/subscribers.interface';
import { FieldTypeEnum } from '../../enums/field-type.enum';

/* Get app state */
export const appState = (state: State) => state.app;

export const getChannelTypes = createSelector(appState, (state: AppState) =>
  state.channelTypes.filter((item) => item.active)
);

export const getChannels = createSelector(
  appState,
  (state: AppState) => state.channels
);

export const hasChannels = createSelector(
  appState,
  (state: AppState) => state.channels.length > 0
);

export const getVkGroups = createSelector(
  appState,
  (state: AppState) => state.vkGroups
);

export const getFlows = (type: 'broadcast' | 'default' = 'default') =>
  createSelector(appState, (state: AppState) =>
    state.flows.filter((item: any) => item.type === type)
  );

export const getSortedFlows = (type: 'broadcast' | 'default' = 'default') =>
  createSelector(appState, (state: AppState) =>
    state.flows
      .filter((item: any) => item.type === type)
      .sort((a: any, b: any) =>
        !b.createdAt
          ? -1
          : a.createdAt > b.createdAt
          ? -1
          : a.createdAt < b.createdAt
          ? 1
          : 0
      )
  );

export const getActiveFlowLength = (flowId: string) =>
  createSelector(
    appState,
    (state: AppState) =>
      state.flows.filter(
        (item: any) =>
          item.id !== flowId &&
          item.enabled &&
          item.type === 'default' &&
          item.channelId != null
      ).length
  );

export const getAllFlows = createSelector(
  appState,
  (state: AppState) => state.flows
);

export const getFlow = createSelector(
  appState,
  (state: AppState) => state.flow
);

export const getChannelWithAllTypes = createSelector(
  appState,
  (state: AppState) =>
    state.channelTypes
      .filter((item) => item.active || item.type === ChannelTypeEnum.ALL)
      .reverse()
);

export const getFlowsByChannelType = (type: ChannelTypeEnum) =>
  createSelector(
    getFlows(),
    getChannels,
    (flows: Flow[], channels: IChannel[]) => {
      if (type === ChannelTypeEnum.ALL) {
        return flows;
      }
      return flows.filter((flow) => {
        let flowChannel = channels.find((s) => s.id === flow.channelId);
        return flowChannel != null && flowChannel.type === type;
      });
    }
  );

export const getChannelById = (channelId?: string) =>
  createSelector(getChannels, (channels: IChannel[]) =>
    channels.find((channel) => channel.id === channelId)
  );

export const getChannelByType = (type: string) =>
  createSelector(getChannels, (channels: IChannel[]) =>
    channels.filter(
      (channel) => channel.type === type || type === 'ALL_CHANNELS'
    )
  );

export const getChannelByAdapterId = (id: string) =>
  createSelector(getChannels, (channels: IChannel[]) =>
    channels.find((channel) => channel.adapter.id === id)
  );

export const getChannelsByAdapterId = (ids: string[]) =>
  createSelector(getChannels, (channels: IChannel[]) =>
    channels.filter((channel) => ids.some((id) => channel.adapter.id === id))
  );

export const tags = createSelector(appState, (state: AppState) => state.tags);

export const getTag = (id: string) =>
  createSelector(tags, (tags: Tag[]) => tags.find((tag) => tag.id === id));

export const getTagById = (id: string) =>
  createSelector(tags, (tags: Tag[]) => tags.find((tag) => tag.id === id));

export const getBroadcast = createSelector(
  appState,
  (state: AppState) => state.broadcast
);

export const getAllShared = createSelector(
  appState,
  (state: AppState) => state.shared
);

export const getMeRefInfo = createSelector(
  appState,
  (state: AppState) => state.meRefInfo
);

export const getCustomFieldsSelector = createSelector(
  appState,
  (state: AppState) => state.customFields
);

export const getGlobalFieldsSelector = createSelector(
  appState,
  (state: AppState) => state.globalFields
);

export const getCustomFieldsByTypeSelector = (type: FieldTypeEnum) =>
  createSelector(appState, (state: AppState) =>
    state.customFields.filter((customField) => customField.type === type)
  );

export const getCustomFieldsByTypesSelector = (types: FieldTypeEnum[]) =>
  createSelector(appState, (state: AppState) =>
    state.customFields.filter((customField) =>
      types.find((value) => customField.type === value)
    )
  );

export const getCustomFieldsByName = (id: string) =>
  createSelector(appState, (state: AppState) =>
    state.customFields.find((customField) => customField.id === id)
  );
export const getGlobalFieldsByName = (id: string) =>
  createSelector(appState, (state: AppState) =>
    state.globalFields.find((globalField) => globalField.id === id)
  );

export const getGlobalFieldsByType = (type: FieldTypeEnum) =>
  createSelector(appState, (state: AppState) =>
    state.globalFields.filter((globalField) => globalField.type === type)
  );

export const getPaymentsSelector = createSelector(
  appState,
  (state: AppState) => state.payments
);

export const getConnectedIntegrationsSelector = createSelector(
  appState,
  (state: AppState) => state.connectedIntegrations
);

export const getPaymentByIdSelector = (id: string) =>
  createSelector(appState, (state: AppState) =>
    state.payments.find((payment) => payment.id === id)
  );

export const getIsShowStatisticsSelector = createSelector(
  appState,
  (state: AppState) => state.isShowStatistics
);

export const getErrorUpdateTokenChannelSelector = createSelector(
  appState,
  (state: AppState) => state.errorUpdateTokenChannel
);

export const isCloseRefreshTokenSelector = createSelector(
  appState,
  (state: AppState) => state.isCloseRefreshTokenDialog
);

export const isShowBigSidebar = createSelector(
  appState,
  (state: AppState) => state.isShowBigSidebar
);

export const selectStatistic = createSelector(
  appState,
  (state: AppState) => state.statistic
);

export const selectShowContent = createSelector(
  appState,
  (state: AppState) => state.isShowContent
);

export const selectBusinessConnection = createSelector(
  appState,
  (state: AppState) => state.businessConnections
);
