<div class="toast">
  <span
    [ngClass]="data.type"
    class="icon {{ data.icon }}"
  >
  </span>
  <div class="toast-info">
    <div class="toast-title" [innerHTML]="data.title | translate"></div>
    <div class="toast-message" [innerHTML]="data.message | translate"></div>
  </div>
  <span class="icon icon-close" (click)="snackBarRef.dismiss()"></span>
</div>
