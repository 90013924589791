<div class="dialog">
  <span class="icon icon-close" (click)="closeModal()"></span>

  <span class="icon {{ data.icon }} dialog-icon"></span>

  <div class="dialog-title">
    {{ data.title | translate }}
  </div>

  <div class="dialog-social-network" *ngIf="data.type === 'use'">
    <app-select
      [(ngModel)]="channelId"
      labelKey="type"
      valueKey="id"
      [label]="'UNIVERSAL_MODAL.CHOSE_SOCIAL_NETWORK' | translate"
      [items]="data.channels">
      <ng-template appOptionTmp let-item="item">
        <div class="channel-type-item d-flex align-items-center">
          <img src="./assets/icons/{{ item.type }}.svg" alt="" />
          <span class="channel-type-item__text">{{ item.adapter.name }}</span>
        </div>
      </ng-template>

      <ng-template appLabelTmp let-item="item">
        <div class="channel-type-item p-0 d-flex align-items-center">
          <img src="./assets/icons/{{ item.type }}.svg" alt="" />
          <span class="channel-type-item__text">{{ item.adapter.name }}</span>
        </div>
      </ng-template>
    </app-select>
    <app-input
      [(ngModel)]="botName"
      [hintText]="'UNIVERSAL_MODAL.HINT_TEXT' | translate"
      [hintTextSecond]="'24/?'"
      class="w-100"
      [label]="'AddBotComponent.BOT_NAME' | translate"
    ></app-input>
  </div>

  <div class="dialog-text" *ngIf="data.text">
    {{ data.text | translate }}
  </div>

  <div class="dialog-btn-group">
    <button class="btn btn-primary btn-sm"
            [disabled]="data.type === 'use' && !channelId"
            (click)="closeModal(data.type)">
      {{ data.btn | translate }}
    </button>
    <button class="btn btn-secondary btn-sm" (click)="closeModal()">
      {{ data.btnBack | translate }}
    </button>
  </div>
</div>
