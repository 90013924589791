import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
})
export class UiInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild('formField', { static: false }) formField!: ElementRef;

  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  label!: string;

  @Input()
  placeholder = '';

  @Input()
  prefixIcon!: string;

  @Input()
  suffixIcon!: string;

  @Input()
  suffixIconTooltip!: string;

  @Input()
  suffixIconTooltipClass!: string;

  @Input()
  type:
    | 'email'
    | 'password'
    | 'text'
    | 'number'
    | 'date'
    | 'datetime-local'
    | 'textarea'
    | 'time' = 'text';

  @Input()
  disabled = false;

  @Input()
  text!: string;

  @Input()
  maxLength: number = 250;

  @Input()
  max: string;

  @Input()
  min: string;

  @Input()
  hint: boolean = false;

  @Input()
  maxLengthInlineHint: boolean = false;

  @Input()
  hintText!: string;

  @Input()
  hintTextSecond!: string;

  @Input()
  tooltip!: string;

  @Input()
  hintTextError!: string;

  @Input()
  mask: any;

  @Input()
  readonly: boolean = false;

  @Input() size: 'extra-small' | 'default' = 'default';

  @Input()
  isPassword = false;

  inputControl: string = '';
  isClickIconUsed!: boolean;
  focused!: boolean;

  @Output() clickIcon: EventEmitter<any> = new EventEmitter();

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.isClickIconUsed = this.clickIcon.observed;
  }

  onInputChange(): void {
    let value;
    if (this.type === 'number') {
      value = +this.inputControl;
      if (value > +this.max) value = +this.max;
      else if (value < +this.min) value = +this.min;
    } else {
      value = this.inputControl;
    }
    this.onChange(value);
  }

  onDownEnter(event: KeyboardEvent): void {
    if (['Enter', ','].includes(event.key) && this.inputControl.trim().length) {
      this.onEnter.emit(this.inputControl);
      event.preventDefault();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.inputControl = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onClickIcon() {
    this.clickIcon.emit(this.type);
  }

  public showPassword(): void {
    this.type = this.type === 'text' ? 'password' : 'text';
  }

  focus(): void {
    this.focused = true;
  }

  anFocus() {
    this.focused = false;
  }
}
