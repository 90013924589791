
﻿import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class RoleCheckerGuard  {
  constructor(private router: Router, private jwtHelper: JwtHelperService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const token = this.jwtHelper.decodeToken();
    if (!token['authorities']) return this.router.navigate(['/app']);
    return token['authorities'].includes(route.data['role']);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.canActivate(childRoute);
  }
}
