import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AgentInterface,
  ProfileInterface,
} from '../interfaces/agent.interface';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private url = `${environment.apiUrl}/api/${environment.apiVersion}`;
  constructor(private _http: HttpClient) {}

  getAgents(): Observable<AgentInterface[]> {
    return this._http.get<AgentInterface[]>(this.url + '/user-service/agents/');
  }

  createAgent(data: any): Observable<AgentInterface> {
    return this._http.put<AgentInterface>(
      this.url + '/user-service/agents/',
      data
    );
  }

  updateAgent(data: any): Observable<AgentInterface> {
    return this._http.post<AgentInterface>(
      this.url + '/user-service/agents/',
      data
    );
  }

  toggleAgent(id: string, data: any): Observable<AgentInterface> {
    return this._http.patch<AgentInterface>(
      this.url + `/user-service/agents/${id}`,
      data
    );
  }

  deleteAgent(id: string): Observable<void> {
    return this._http.delete<void>(this.url + `/user-service/agents/${id}/`);
  }

  getProfile(): Observable<ProfileInterface[]> {
    return this._http.get<ProfileInterface[]>(
      this.url + '/user-service/profiles/'
    );
  }
}
