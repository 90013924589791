import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable, Injector } from '@angular/core';
import {
  ToastComponent,
  ToastDataInterface,
  ToastType,
} from '../../modules/panel/components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private snackBar: MatSnackBar;

  constructor(private injector: Injector) {}

  private getSnackBar(): MatSnackBar {
    if (!this.snackBar) {
      this.snackBar = this.injector.get(MatSnackBar);
    }
    return this.snackBar;
  }

  openToast(
    message: string,
    title: string = 'ProfileComponent.SUCCESS_SAVE',
    type: ToastType = 'success',
    icon?: string
  ): void {
    this.getSnackBar().openFromComponent<ToastComponent, ToastDataInterface>(
      ToastComponent,
      {
        panelClass: 'custom-snackbar',
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
        data: {
          type,
          title,
          message,
          icon
        },
      }
    );
  }
}
