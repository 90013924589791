import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, switchMap } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { TemplateInterface } from '../interfaces/template.interface';
import { Type } from '../../modules/panel/pages/template/components/t-card/t-card.component';
import { UniversalModalComponent } from '../../modules/panel/pages/template/components/universal-modal/universal-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FlowService } from './flow.service';
import { Router } from '@angular/router';
import { ModalService } from './modal.service';
import { IChannel } from '../interfaces/channel.interface';
import { ChannelService } from './channel.service';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { getChannels } from '../store/app/app.selectors';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  private _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/flow/templates`;
  constructor(
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _flowService: FlowService,
    private _modalService: ModalService,
    private _channelService: ChannelService,
    private _store: Store<State>,
    private _router: Router
  ) {}

  getShared(): Observable<TemplateInterface[]> {
    return this._http.get<TemplateInterface[]>(`${this._apiUrl}/shared`);
  }

  flowImport(body: any): Observable<any> {
    body.draft = cloneDeep(body.live);

    return this._http.post(`${env.apiUrl}/api/v1/flow/import`, body);
  }

  changeHandler(event: { type: Type; id?: string }): void {
    let sharedId = '';
    if (event.id) {
      sharedId = event.id;
    }
    this._store.select(getChannels).subscribe((value) => {
      const modal = this._dialog.open(UniversalModalComponent, {
        width: '390px',
        data: this.generateData(event.type, value),
      });

      modal.afterClosed().subscribe((res) => {
        if (!res || !res.type) {
          return;
        }

        switch (event.type) {
          case 'use': {
            delete res.type;
            this.flowImport1(sharedId, res);
            break;
          }
          case 'open': {
            this._router.navigate(['/app/flow/builder/' + sharedId + '/edit']);
            break;
          }
          case 'copy': {
            this._router.navigate(['/app/flow/builder/' + sharedId + '/edit']);
            break;
          }
          case 'no_copy': {
            this.changeHandler({ type: 'use', id: sharedId });
            break;
          }
          case 'no_social': {
            // this._modalService.openAddChannel();
            break;
          }
        }
      });
    });
  }

  flowImport1(id: string, data: any): void {
    this._flowService
      .getFlowSearch(id)
      .pipe(
        switchMap((res) => this.flowImport({ ...res, ...data })),
        catchError((err) => {
          this.changeHandler({ type: 'no_copy' });
          throw err;
        })
      )
      .subscribe((value) => {
        this.changeHandler({ type: 'copy', id: value.id });
      });
  }

  private generateData(type: Type, channels: IChannel[]) {
    return {
      type,
      ...(type === 'use' && { channels: channels }),
      icon: type === 'copy' || type === 'use' ? 'icon-run' : 'icon-hand-right',
      title: `UNIVERSAL_MODAL.${type}_TITLE`.toUpperCase(),
      text:
        type !== 'copy' && type !== 'use'
          ? `UNIVERSAL_MODAL.${type}_TEXT`.toUpperCase()
          : '',
      btn: `UNIVERSAL_MODAL.${type}_BTN_1`.toUpperCase(),
      btnBack: `UNIVERSAL_MODAL.${type}_BTN_2`.toUpperCase(),
    };
  }
}
