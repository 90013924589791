import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

export type ToastType = 'success' | 'error';

export interface ToastDataInterface {
  type: ToastType;
  title: string;
  message: string;
  icon?: string;
}

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef);
  public data: ToastDataInterface = inject(MAT_SNACK_BAR_DATA);

  ngOnInit(): void {
    this.data.icon =
      this.data.icon ||
      (this.data.type === 'success' ? 'icon-check-outline' : 'icon-2fa');
  }
}
