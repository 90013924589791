import { createAction, props } from '@ngrx/store';
import { IVkGroup } from '../../interfaces/vk-group.interface';
import { IChannel } from '../../interfaces/channel.interface';
import { Flow } from '../../interfaces/bot.interface';
import { Tag } from '../../interfaces/subscribers.interface';
import { IBroadcast } from '../../interfaces/broadcast.interface';
import { SegmentInterface } from '../../interfaces/segment.interface';
import { TemplateInterface } from '../../interfaces/template.interface';
import { MeReferralInfo } from '../../interfaces/me-referral-info.interface';
import {
  CustomFieldInterface,
  GlobalFieldInterface,
} from '../../interfaces/custom-field.interface';
import { PaymentInterface } from '../../interfaces/payment.interface';
import { StatisticChannelInterface } from '../../interfaces/statistics.interface';
import { BusinessConnection } from '../../interfaces/business-connection.interface';

export enum AppActionTypes {
  AddChannel = '[App] Add channel',
  GetChannels = '[App] Get channels',
  GetChannelsSuccess = '[App] Get channels success',
  GetChannelsFailed = '[App] Get channels failed',
  UpdateChannelSuccess = '[App] Get channel success',
  GetCurrentFlow = '[App] Get current flow',
  GetCurrentFlowSuccess = '[App] Get current flow success',
  GetCurrentFlowFailed = '[App] Get current flow failed',
  GetFlows = '[App] Get flows',
  GetFlowsSuccess = '[App] Get flows success',
  GetFlowsFailed = '[App] Get flows failed',
  AddFlow = '[App] Add flow failed',
  AddFlowSuccess = '[App] Add flow success',
  AddFlowFailed = '[App] Add flow failed',
  DeleteChannel = '[App] Delete channel',
  DeleteChannelSuccess = '[App] Delete channel success',
  DeleteChannelFailed = '[App] Delete channels failed',
  AddVkGroups = '[App] Add vk groups',
  DeleteFlow = '[App] Delete flow',
  DeleteFlowSuccess = '[App] Delete flow success',
  DeleteFlowFailed = '[App] Delete flow failed',
  CloneFlow = '[App] Clone flow',
  CloneFlowSuccess = '[App] Clone flow success',
  CloneFlowFailed = '[App] Clone flow failed',
  UpdateFlow = '[App] Update flow',
  UpdateFlowSuccess = '[App] Update flow success',
  UpdateFlowFailed = '[App] Update flow failed',
  GetFlowById = '[App] Get flow by id',
  GetTags = '[App] Get tags',
  GetTagsSuccess = '[App] Get success',
  GetTagsFailed = '[App] Get failed',
  AddTag = '[App] Add Tag',
  AddTagSuccess = '[App] Add Tag success',
  AddTagFailed = '[App] Add Tag failed',
  UpdateTag = '[App] Update Tag',
  UpdateTagSuccess = '[App] Update Tag success',
  UpdateTagFailed = '[App] Update Tag failed',
  RemoveTag = '[App] Remove Tag',
  RemoveTagSuccess = '[App] Remove Tag success',
  RemoveTagFailed = '[App] Remove Tag failed',
  AddBroadcast = '[App] Add Broadcast Tag',
  AddBroadcastSuccess = '[App] Add Broadcast success',
  UpdateBroadcastSuccess = '[App] Update Broadcast success',
  AddBroadcastFailed = '[App] Add Broadcast failed',
  GetBroadcast = '[App] Get Broadcast Tag',
  GetBroadcastSuccess = '[App] Get Broadcast success',
  GetBroadcastFailed = '[App] Get Broadcast failed',
  BroadcastSchedule = '[App] Broadcast Schedule',
  BroadcastScheduleSuccess = '[App] Broadcast Schedule success',
  GetShared = '[App] getShared',
  GetSharedSuccess = '[App] getShared success',
  GetSharedFailed = '[App] getShared failed',
  GetMeReferralInfo = '[App] GetMeReferralInfo',
  GetMeReferralInfoSuccess = '[App] GetMeReferralInfoSuccess',
  GetCustomFields = '[App] Get CustomFields',
  GetCustomFieldsSuccess = '[App] Get CustomFieldsSuccess',
  CreateCustomField = '[App] Create CustomField',
  CreateCustomFieldSuccess = '[App] Create CustomFieldSuccess',
  DeleteCustomField = '[App] Delete CustomField',
  DeleteCustomFieldSuccess = '[App] Delete CustomFieldSuccess',
  UpdateCustomField = '[App] Update CustomField',
  UpdateCustomFieldSuccess = '[App] Update CustomFieldSuccess',
  GetGlobalFields = '[App] Get GlobalFields',
  GetGlobalFieldsSuccess = '[App] Get GlobalFieldsSuccess',
  CreateGlobalField = '[App] Create GlobalField',
  CreateGlobalFieldSuccess = '[App] Create GlobalFieldSuccess',
  DeleteGlobalField = '[App] Delete GlobalField',
  DeleteGlobalFieldSuccess = '[App] Delete GlobalFieldSuccess',
  UpdateGlobalField = '[App] Update GlobalField',
  UpdateGlobalFieldSuccess = '[App] Update GlobalFieldSuccess',
  GetPayments = '[App] Get payment',
  GetPaymentsSuccess = '[App] Get payment success',
  DeletePayment = '[App] Delete payment',
  DeletePaymentSuccess = '[App] Delete payment success',
  CreatePayment = '[App] Create payment',
  CreatePaymentSuccess = '[App] Create payment success',
  UpdatePayment = '[App] Update payment',
  UpdatePaymentSuccess = '[App] Update payment success',
  ToggleShowStatistics = '[App] Toggle statistics',
  UpdateToken = '[App] Update Update token',
  UpdateTokenSuccess = '[App] Update token success',
  UpdateTokenFailed = '[App] Update token failed',
  ToggleSidebar = '[App] Toggle sidebar',
  GetConnectedIntegrations = '[App] getConnectedIntegrations',
  GetConnectedIntegrationsSuccess = '[App] getConnectedIntegrations success',
  GetStatistic = '[App] GetStatistic',
  GetStatisticSuccess = '[App] GetStatistic success',
  ToggleShowContent = '[App] ToggleShowContent',

  GetBusinessConnection = '[App] GetBusinessConnection',
  GetBusinessConnectionSuccess = '[App] GetBusinessConnection success',
  GetBusinessConnectionFailed = '[App] GetBusinessConnection failed',

  GetConversionPoints = '[App] GetConversionPoints',
  GetConversionPointsSuccess = '[App] GetConversionPoints success',
  GetConversionPointsFailed = '[App] GetConversionPoints failed',

  ResetState = '[App] Reset state',
}

export const addChannelAction = createAction(
  AppActionTypes.AddChannel,
  props<{ channel: IChannel }>()
);
export const getChannelsAction = createAction(AppActionTypes.GetChannels);
export const getChannelsSuccessAction = createAction(
  AppActionTypes.GetChannelsSuccess,
  props<{ channels: IChannel[] }>()
);
export const getChannelsFailedAction = createAction(
  AppActionTypes.GetChannelsFailed,
  props<{ errors: any }>()
);

export const deleteChannelAction = createAction(
  AppActionTypes.DeleteChannel,
  props<{ channelId: string }>()
);
export const deleteChannelSuccessAction = createAction(
  AppActionTypes.DeleteChannelSuccess,
  props<{ channelId: string }>()
);
export const deleteChannelFailedAction = createAction(
  AppActionTypes.DeleteChannelFailed,
  props<{ errors: any }>()
);

export const addVkGroups = createAction(
  AppActionTypes.AddVkGroups,
  props<{ groups: IVkGroup[] }>()
);

export const getCurrentFlow = createAction(
  AppActionTypes.GetCurrentFlow,
  props<{ id: string }>()
);

export const getCurrentFlowSuccess = createAction(
  AppActionTypes.GetCurrentFlowSuccess,
  props<{ flow: Flow }>()
);

export const getCurrentFlowFailed = createAction(
  AppActionTypes.GetCurrentFlowFailed,
  props<{ errors: any }>()
);

export const getFlowsAction = createAction(AppActionTypes.GetFlows);

export const getFlowsSuccessAction = createAction(
  AppActionTypes.GetFlowsSuccess,
  props<{ flows: Flow[] }>()
);

export const getFlowsFailedAction = createAction(
  AppActionTypes.GetFlowsFailed,
  props<{ errors: any }>()
);

export const addFlowAction = createAction(
  AppActionTypes.AddFlow,
  props<{ flow: Flow; redirectToBuilder?: boolean }>()
);

export const addFlowSuccessAction = createAction(
  AppActionTypes.AddFlowSuccess,
  props<{ flow: Flow; redirectToBuilder?: boolean }>()
);

export const addFlowFailedAction = createAction(
  AppActionTypes.AddFlowFailed,
  props<{ errors: any }>()
);

export const deleteFlow = createAction(
  AppActionTypes.DeleteFlow,
  props<{ id: string }>()
);

export const deleteFlowSuccess = createAction(
  AppActionTypes.DeleteFlowSuccess,
  props<{ id: string }>()
);

export const deleteFlowFailed = createAction(
  AppActionTypes.DeleteFlowFailed,
  props<{ errors: any }>()
);

export const cloneFlow = createAction(
  AppActionTypes.CloneFlow,
  props<{ id: string; name: string; channelId: string }>()
);

export const cloneFlowSuccess = createAction(
  AppActionTypes.CloneFlowSuccess,
  props<{ flow: Flow }>()
);

export const cloneFlowFailed = createAction(
  AppActionTypes.CloneFlowFailed,
  props<{ errors: any }>()
);

export const updateFlow = createAction(
  AppActionTypes.UpdateFlow,
  props<{ flow: Flow }>()
);

export const updateFlowSuccess = createAction(
  AppActionTypes.UpdateFlowSuccess,
  props<{ flow: Flow }>()
);

export const updateFlowFailed = createAction(
  AppActionTypes.UpdateFlowFailed,
  props<{ errors: any }>()
);

export const getTags = createAction(AppActionTypes.GetTags);

export const getTagsSuccess = createAction(
  AppActionTypes.GetTagsSuccess,
  props<{ tags: any[] }>()
);

export const getTagsFailed = createAction(
  AppActionTypes.GetTagsFailed,
  props<{ errors: any }>()
);

export const addTag = createAction(
  AppActionTypes.AddTag,
  props<{ tag: Tag }>()
);

export const addTagSuccess = createAction(
  AppActionTypes.AddTagSuccess,
  props<{ tag: Tag }>()
);

export const addTagFailed = createAction(
  AppActionTypes.AddTagFailed,
  props<{ errors: any }>()
);

export const updateTag = createAction(
  AppActionTypes.UpdateTag,
  props<{ tag: Tag }>()
);

export const updateTagSuccess = createAction(
  AppActionTypes.UpdateTagSuccess,
  props<{ tag: Tag }>()
);

export const updateTagFailed = createAction(
  AppActionTypes.UpdateTagFailed,
  props<{ errors: any }>()
);

export const removeTag = createAction(
  AppActionTypes.RemoveTag,
  props<{ tagId: string }>()
);

export const removeTagSuccess = createAction(
  AppActionTypes.RemoveTagSuccess,
  props<{ tagId: string }>()
);

export const removeTagFailed = createAction(
  AppActionTypes.RemoveTagFailed,
  props<{ errors: any }>()
);

export const addBroadcast = createAction(
  AppActionTypes.AddBroadcast,
  props<{ broadcast: IBroadcast; navigate: boolean }>()
);

export const addBroadcastSuccess = createAction(
  AppActionTypes.AddBroadcastSuccess,
  props<{ broadcast: IBroadcast }>()
);

export const updateBroadcastSuccess = createAction(
  AppActionTypes.UpdateBroadcastSuccess,
  props<{ broadcast: IBroadcast }>()
);

export const addBroadcastFailed = createAction(
  AppActionTypes.AddBroadcastFailed,
  props<{ errors: any }>()
);

export const getBroadcast = createAction(
  AppActionTypes.GetBroadcast,
  props<{ broadcastId: string }>()
);

export const getBroadcastSuccess = createAction(
  AppActionTypes.GetBroadcastSuccess,
  props<{ broadcast: IBroadcast }>()
);

export const getBroadcastFailed = createAction(
  AppActionTypes.GetBroadcastFailed,
  props<{ errors: any }>()
);

export const broadcastSchedule = createAction(
  AppActionTypes.BroadcastSchedule,
  props<{ broadcast: IBroadcast | undefined; navigate?: boolean | undefined }>()
);

export const broadcastScheduleSuccess = createAction(
  AppActionTypes.BroadcastScheduleSuccess,
  props<{ broadcast: IBroadcast | undefined; navigate?: boolean | undefined }>()
);

export const getShared = createAction(AppActionTypes.GetShared);

export const getSharedSuccess = createAction(
  AppActionTypes.GetSharedSuccess,
  props<{ shared: TemplateInterface[] }>()
);

export const getSharedFailed = createAction(
  AppActionTypes.GetSharedFailed,
  props<{ errors: any }>()
);

export const getMeReferralInfoAction = createAction(
  AppActionTypes.GetMeReferralInfo
);

export const getMeReferralInfoSuccessAction = createAction(
  AppActionTypes.GetMeReferralInfoSuccess,
  props<{ params: MeReferralInfo }>()
);

export const getCustomFields = createAction(AppActionTypes.GetCustomFields);

export const getCustomFieldsSuccess = createAction(
  AppActionTypes.GetCustomFieldsSuccess,
  props<{ content: CustomFieldInterface[] }>()
);

export const createCustomField = createAction(
  AppActionTypes.CreateCustomField,
  props<{ customField: CustomFieldInterface }>()
);

export const createCustomFieldSuccess = createAction(
  AppActionTypes.CreateCustomFieldSuccess,
  props<{ customField: CustomFieldInterface }>()
);

export const deleteCustomField = createAction(
  AppActionTypes.DeleteCustomField,
  props<{ id: string }>()
);

export const deleteCustomFieldSuccess = createAction(
  AppActionTypes.DeleteCustomFieldSuccess,
  props<{ id: string }>()
);

export const updateCustomField = createAction(
  AppActionTypes.UpdateCustomField,
  props<{ customField: CustomFieldInterface }>()
);

export const updateCustomFieldSuccess = createAction(
  AppActionTypes.UpdateCustomFieldSuccess,
  props<{ newCustomField: CustomFieldInterface }>()
);

export const getGlobalFields = createAction(AppActionTypes.GetGlobalFields);

export const getGlobalFieldsSuccess = createAction(
  AppActionTypes.GetGlobalFieldsSuccess,
  props<{ content: GlobalFieldInterface[] }>()
);

export const createGlobalField = createAction(
  AppActionTypes.CreateGlobalField,
  props<{ globalField: GlobalFieldInterface }>()
);

export const createGlobalFieldSuccess = createAction(
  AppActionTypes.CreateGlobalFieldSuccess,
  props<{ globalField: GlobalFieldInterface }>()
);

export const deleteGlobalField = createAction(
  AppActionTypes.DeleteGlobalField,
  props<{ id: string }>()
);

export const deleteGlobalFieldSuccess = createAction(
  AppActionTypes.DeleteGlobalFieldSuccess,
  props<{ id: string }>()
);

export const updateGlobalField = createAction(
  AppActionTypes.UpdateGlobalField,
  props<{ globalField: GlobalFieldInterface }>()
);

export const updateGlobalFieldSuccess = createAction(
  AppActionTypes.UpdateGlobalFieldSuccess,
  props<{ newGlobalField: GlobalFieldInterface }>()
);

export const getPayments = createAction(AppActionTypes.GetPayments);

export const getPaymentsSuccess = createAction(
  AppActionTypes.GetPaymentsSuccess,
  props<{ payments: PaymentInterface[] }>()
);

export const deletePayment = createAction(
  AppActionTypes.DeletePayment,
  props<{ ids: string[] }>()
);

export const deletePaymentSuccess = createAction(
  AppActionTypes.DeletePaymentSuccess,
  props<{ ids: string[] }>()
);

export const createPayment = createAction(
  AppActionTypes.CreatePayment,
  props<{ payment: PaymentInterface }>()
);

export const createPaymentSuccess = createAction(
  AppActionTypes.CreatePaymentSuccess,
  props<{ newPayment: PaymentInterface }>()
);

export const updatePayment = createAction(
  AppActionTypes.UpdatePayment,
  props<{ payment: PaymentInterface }>()
);

export const updatePaymentSuccess = createAction(
  AppActionTypes.UpdatePaymentSuccess,
  props<{ newPayment: PaymentInterface }>()
);

export const toggleShowStatistics = createAction(
  AppActionTypes.ToggleShowStatistics
);

export const updateTokenChannel = createAction(
  AppActionTypes.UpdateToken,
  props<{ id: string; token: string; typeKey: string }>()
);

export const updateTokenChannelSuccess = createAction(
  AppActionTypes.UpdateTokenSuccess,
  props<{ channel: IChannel }>()
);

export const updateTokenChannelFailed = createAction(
  AppActionTypes.UpdateTokenFailed,
  props<{ error: any }>()
);

export const getConnectedIntegrations = createAction(
  AppActionTypes.GetConnectedIntegrations
);

export const getConnectedIntegrationsSuccess = createAction(
  AppActionTypes.GetConnectedIntegrationsSuccess,
  props<{ data: any }>()
);

export const toggleSideBar = createAction(AppActionTypes.ToggleSidebar);

export const updateChannelSuccess = createAction(
  AppActionTypes.UpdateChannelSuccess,
  props<{ channel: IChannel }>()
);

export const getStatisticAction = createAction(
  AppActionTypes.GetStatistic,
  props<{ channelAdapterIds: string[] }>()
);

export const getStatisticSuccessAction = createAction(
  AppActionTypes.GetStatisticSuccess,
  props<{ statistic: StatisticChannelInterface }>()
);

export const toggleShowContentAction = createAction(
  AppActionTypes.ToggleShowContent
);

export const getBusinessConnection = createAction(
  AppActionTypes.GetBusinessConnection
);

export const getBusinessConnectionSuccess = createAction(
  AppActionTypes.GetBusinessConnectionSuccess,
  props<{ data: BusinessConnection[] }>()
);

export const getBusinessConnectionFailed = createAction(
  AppActionTypes.GetBusinessConnectionFailed,
  props<{ error: any }>()
);

export const resetStateAction = createAction(AppActionTypes.ResetState);
