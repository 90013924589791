import { Product } from './user.info';
import { Total } from './order-history.interface';

export interface UserPackage {
  active: boolean;
  productId: TariffEnum;
  expiresAt: string;
  options: any[];
  limits: Limit[];
  expired: boolean;
  maximumAllowedLimitReached: boolean;
  product?: Product;
  nextPayment?: NextPayment;
}

export interface NextPayment {
  date: string;
  total: Total;
  items: Item[];
  method: Method;
}

export interface Method {
  id: string;
  active: boolean;
  deactivationReason: any;
  type: string;
  title: string;
  last4: string;
  card_type: string;
  issuer_country: string;
  issuer_name: any;
  uniqueKey: string;
  creationDate: string;
  updateDate: string;
  userId: string;
}

export interface Item {
  type: string;
  subtotal: Total;
  productId: string;
  productType: string;
}

export interface Limit {
  type: LimitType;
  totalPrice: TotalPrice;
  available?: boolean;
  userSeats?: number;
  freeSeats?: number;
  totalSeats: number;
  usedSeats: number;
  pricePerSeat?: PricePerSeat;
  unlimitedSeats?: boolean;
  freePricePlanId?: string;
  userPricePlanId?: string;
  subscribersTotal?: number;
  subscribersUsed?: number;
  unlimitedSubscribers?: boolean;
}

export interface TotalPrice {
  value: number;
  currency: string;
}

export interface PricePerSeat {
  value: number;
  currency: string;
}

export enum LimitType {
  USERS_SEATS = 'users_seats',
  AGENT_SEATS = 'agent_seats',
  ACTIVE_FLOW_SEATS = 'active_flow_seats',
  CHANNEL_SEATS = 'channel_seats',
  BROADCASTING = 'broadcasting',
  BOTMAN_AI = 'botman_ai',
}

export enum TariffEnum {
  FREE = '1',
  PRO = '2',
  UNLIMITED = '3',
}
