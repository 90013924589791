<div
  class="ui-select {{ size }}"
  cdkOverlayOrigin
  appOutsideClick
  #trigger="cdkOverlayOrigin"
  [class.disabled]="disable"
  (click)="toggleDropdown()"
  (clickOutside)="isOpen = false"
>
  <div class="ui-select__label" *ngIf="label">
    <span [innerHTML]="label | translate"></span>
    <span
      *ngIf="tooltip?.length"
      matTooltipPosition="above"
      [matTooltip]="tooltip | translate"
      class="icon icon-info-circle"
    ></span>
  </div>
  <div
    class="ui-select__container d-flex align-items-center justify-content-between"
    [ngStyle]="{
      border: disable ? 'none' : '1px solid #a3a4a8'
    }"
  >
    <div class="ui-select__content">
      <div class="ui-select__content_placeholder" *ngIf="!selected">
        {{ placeholder || 'Выберите значение' }}
      </div>
      <div
        class="ui-select__content_value d-flex align-items-center column-gap-8"
        *ngIf="selected"
      >
        <ng-template #defaultLabelTemplate>
          <span *ngIf="selected?.icon" class="icon {{ selected.icon }}"></span>
          <span
            class="text d-flex align-items-center w-100"
            [innerHTML]="
              (labelKey
                ? selected[labelKey]
                : selected.label
                ? selected.label
                : 'AddChannelComponent.' + selected.type
              )
                | translate
                | safeHtml
            "
          ></span>
        </ng-template>
        <ng-template
          [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
          [ngTemplateOutletContext]="{ item: selected, item$: selected }"
        >
        </ng-template>
      </div>
    </div>
    <span class="icon icon-right ui-select__arrow"></span>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
>
  <div class="ui-select-dropdown {{dropdownClass}}">
    <ng-container *ngFor="let item of items; let i = index">
      <div class="ui-select-dropdown__item" (click)="onSelect(item)">
        <ng-template #defaultOptionTemplate>
          <div
            class="ui-select-dropdown-item"
            [innerHTML]="
              (labelKey ? item[labelKey] : item.label) | translate | safeHtml
            "
          ></div>
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
          [ngTemplateOutletContext]="{ item: item, item$: item, index: i }"
        >
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>
