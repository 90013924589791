<div class="ui-input {{ size }}">
  <div class="ui-input__label" *ngIf="label?.length">
    <span [innerHTML]="label | translate"></span>
    <span
      *ngIf="tooltip?.length"
      matTooltipPosition="above"
      [matTooltip]="tooltip | translate"
      class="icon icon-info-circle"
    ></span>
  </div>
  <div
    *ngIf="type !== 'textarea'; else textarea"
    class="ui-input__container"
    [class.ng-dirty]="hintTextError && !hintText"
    [class.disabled]="disabled"
    appOutsideClick
    [class.focused]="focused"
    (clickOutside)="anFocus()"
    (click)="formField.focus(); focus()"
  >
    <div (click)="onClickIcon()" class="ui-input__prefix" *ngIf="prefixIcon">
      <span class="icon {{ prefixIcon }}"></span>
    </div>

    <input
      [inputMask]="mask"
      class="ui-input__input"
      #formField
      [type]="type"
      [min]="min"
      [max]="max"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [(ngModel)]="inputControl"
      (keydown)="onDownEnter($event)"
      [maxlength]="maxLength"
      [readonly]="readonly"
      (ngModelChange)="onInputChange()"
      autocomplete="on"
    />

    <div *ngIf="text" class="ui-input__text">{{ text }}</div>

    <div class="hint-inline" *ngIf="maxLengthInlineHint">
      <div
        class="hint__length"
        [class.w-100]="!hintText && !hintTextError"
        [class.text-right]="!hintText"
      >
        {{
          hintTextSecond ? hintTextSecond : (inputControl ? inputControl.length : 0) + '/' + maxLength
        }}
      </div>
    </div>

    <div
      class="ui-input__suffix icon icon-close-fill"
      *ngIf="
        inputControl?.length &&
        !disabled &&
        type !== 'date' &&
        type !== 'time' &&
        type !== 'number' &&
        !isPassword && !suffixIcon
      "
      (click)="inputControl = ''; onInputChange()"
    ></div>

    <div *ngIf="!readonly" [class.used]="isClickIconUsed">
      <div
        class="ui-input__suffix"
        *ngIf="suffixIcon?.length"
        (click)="onClickIcon()"
      >
        <span
          #suffixTooltip="matTooltip"
          matTooltipPosition="above"
          [matTooltip]="suffixIconTooltip | translate"
          [matTooltipClass]="suffixIconTooltipClass"
          class="icon {{ suffixIcon }}"
          *ngIf="suffixIconTooltip?.length"
          (click)="suffixTooltip.show()"></span>
        <span
          class="icon {{ suffixIcon }}"
          *ngIf="!suffixIconTooltip?.length"></span>
      </div>
    </div>

    <span
      *ngIf="isPassword"
      (click)="showPassword()"
      class="password-icon icon icon-eye{{
        type === 'password' ? '-slash' : ''
      }}"
    ></span>
  </div>

  <ng-template #textarea>
    <div class="ui-input__textarea">
      <textarea
        #formField
        [disabled]="disabled"
        [placeholder]="placeholder"
        [(ngModel)]="inputControl"
        (keydown)="onDownEnter($event)"
        (ngModelChange)="onInputChange()"
        [maxlength]="maxLength"
        class="ui-input__input"
      ></textarea>
    </div>
  </ng-template>

  <div class="hint" *ngIf="hint">
    <ng-container *ngIf="hintText; else hintError">
      <span class="hint__icon icon icon-check-circle"></span>
      <div class="hint__text">{{ hintText }}</div>
    </ng-container>

    <ng-template #hintError>
      <span
        *ngIf="hintTextError"
        class="hint__icon icon icon-danger-triangle"
      ></span>
      <div
        class="hint__text"
        *ngIf="hintTextError?.length"
        [class.hint-error]="hintTextError"
      >
        {{ hintTextError }}
      </div>
    </ng-template>
    <div
      *ngIf="maxLengthInlineHint"
      class="hint__length"
      [class.w-100]="!hintText && !hintTextError"
      [class.text-right]="!hintText"
    >
      {{
        hintTextSecond
          ? hintTextSecond
          : (inputControl ? inputControl.length : 0) + '/' + maxLength
      }}
    </div>
  </div>
</div>
