import { createReducer, on } from '@ngrx/store';
import { ChannelTypes, IChannelType } from '../../data/channel-types';
import { IVkGroup } from '../../interfaces/vk-group.interface';
import {
  addChannelAction,
  addVkGroups,
  getFlowsSuccessAction,
  getChannelsSuccessAction,
  addFlowSuccessAction,
  deleteFlowSuccess,
  cloneFlowSuccess,
  updateFlowSuccess,
  deleteChannelSuccessAction,
  getTagsSuccess,
  addTagSuccess,
  getBroadcastSuccess,
  updateTagSuccess,
  getCurrentFlowSuccess,
  removeTagSuccess,
  getSharedSuccess,
  getMeReferralInfoSuccessAction,
  getCustomFieldsSuccess,
  createCustomFieldSuccess,
  deleteCustomFieldSuccess,
  updateCustomFieldSuccess,
  getPaymentsSuccess,
  deletePaymentSuccess,
  createPaymentSuccess,
  updatePaymentSuccess,
  toggleShowStatistics,
  updateTokenChannelSuccess,
  updateTokenChannelFailed,
  toggleSideBar,
  getConnectedIntegrationsSuccess,
  updateChannelSuccess,
  addBroadcast,
  getStatisticSuccessAction,
  updateGlobalFieldSuccess,
  getGlobalFieldsSuccess,
  createGlobalFieldSuccess,
  getBusinessConnectionSuccess,
  deleteGlobalFieldSuccess,
  toggleShowContentAction,
  resetStateAction,
} from './app.actions';
import { IChannel } from '../../interfaces/channel.interface';
import { Flow } from '../../interfaces/bot.interface';
import { Tag } from '../../interfaces/subscribers.interface';
import { IBroadcast } from '../../interfaces/broadcast.interface';
import { SegmentInterface } from '../../interfaces/segment.interface';
import { TemplateInterface } from '../../interfaces/template.interface';
import { MeReferralInfo } from '../../interfaces/me-referral-info.interface';
import { CustomFieldInterface, GlobalFieldInterface } from '../../interfaces/custom-field.interface';
import { PaymentInterface } from '../../interfaces/payment.interface';
import { IGetCourseIntegration } from '../../interfaces/integration.models';
import { StatisticChannelInterface } from '../../interfaces/statistics.interface';
import { BusinessConnection } from '../../interfaces/business-connection.interface';

export interface AppState {
  channelTypes: IChannelType[];
  broadcast: IBroadcast | null;
  channels: IChannel[];
  vkGroups: IVkGroup[];
  flows: Flow[];
  flow: Flow | null;
  tags: Tag[];
  segments: SegmentInterface[] | undefined;
  shared: TemplateInterface[];
  meRefInfo: MeReferralInfo | undefined;
  customFields: CustomFieldInterface[];
  globalFields: GlobalFieldInterface[];
  payments: PaymentInterface[];
  isShowStatistics: boolean;
  errorUpdateTokenChannel: any;
  connectedIntegrations: IGetCourseIntegration[];
  isCloseRefreshTokenDialog: boolean;
  isShowBigSidebar: boolean;
  statistic: StatisticChannelInterface;
  businessConnections: BusinessConnection[];
  isShowContent: boolean;
}

export const initialUserState: AppState = {
  channelTypes: ChannelTypes,
  broadcast: null,
  channels: [],
  vkGroups: [],
  flows: [],
  flow: null,
  tags: [],
  segments: undefined,
  shared: [],
  meRefInfo: undefined,
  customFields: [],
  globalFields: [],
  payments: [],
  isShowStatistics: false,
  errorUpdateTokenChannel: null,
  connectedIntegrations: [],
  isCloseRefreshTokenDialog: false,
  isShowBigSidebar: true,
  statistic: {},
  isShowContent: true,
  businessConnections: []
};

const _appReducer = createReducer(
  initialUserState,
  on(getChannelsSuccessAction, (state, action) => ({
    ...state,
    channels: action.channels,
  })),
  on(addChannelAction, (state, action) => ({
    ...state,
    channels: state.channels.concat(action.channel || []),
  })),
  on(addVkGroups, (state, action) => ({
    ...state,
    vkGroups: action.groups,
  })),
  on(getFlowsSuccessAction, (state, action) => ({
    ...state,
    flows: action.flows,
  })),
  on(getCurrentFlowSuccess, (state, action) => ({
    ...state,
    flow: action.flow,
  })),
  on(deleteFlowSuccess, (state, action) => ({
    ...state,
    flows: state.flows.filter((flow) => flow.id !== action.id),
  })),
  on(cloneFlowSuccess, (state, action) => ({
    ...state,
    flows: state.flows.concat(action.flow || []),
  })),
  on(updateFlowSuccess, (state, action) => {
    const index = state.flows.findIndex((flow) => flow.id === action.flow.id);
    const flows = state.flows.concat();
    flows[index] = action.flow;
    return {
      ...state,
      flows,
      flow: action.flow,
    };
  }),
  on(addFlowSuccessAction, (state, action) => ({
    ...state,
    flows: state.flows.concat(action.flow || []),
    flow: action.flow,
  })),
  on(deleteChannelSuccessAction, (state, action) => ({
    ...state,
    flows: state.flows.map((flow: Flow) =>
      flow.channelId === action.channelId ? { ...flow, channelId: undefined } : flow
    ),
    channels: state.channels.filter(
      (channel) => channel.id !== action.channelId
    ),
  })),
  on(getTagsSuccess, (state, action) => ({
    ...state,
    tags: action.tags,
  })),
  on(addTagSuccess, (state, action) => ({
    ...state,
    tags: state.tags.concat(action.tag || []),
  })),
  on(updateTagSuccess, (state, action) => {
    const tagIndex = state.tags.findIndex((item) => item.id === action.tag.id);
    const tags = [...state.tags];
    if (tagIndex !== -1) {
      tags[tagIndex] = action.tag;
    } else {
      tags.unshift(action.tag);
    }
    return {
      ...state,
      tags,
    };
  }),
  on(removeTagSuccess, (state, action) => ({
    ...state,
    tags: state.tags.filter((item) => item.id !== action.tagId),
  })),
  on(getBroadcastSuccess, (state, action) => ({
    ...state,
    broadcast: action.broadcast,
  })),
  on(getSharedSuccess, (state, action) => ({
    ...state,
    shared: action.shared,
  })),
  on(getMeReferralInfoSuccessAction, (state, { params }) => ({
    ...state,
    meRefInfo: params,
  })),
  on(getCustomFieldsSuccess, (state, action) => ({
    ...state,
    customFields: action.content,
  })),
  on(createCustomFieldSuccess, (state, action) => ({
    ...state,
    customFields: [...state.customFields, action.customField],
  })),
  on(deleteCustomFieldSuccess, (state, action) => ({
    ...state,
    customFields: state.customFields.filter((field) => field.id !== action.id),
  })),
  on(updateCustomFieldSuccess, (state, action) => {
    const findIndex = state.customFields.findIndex(
      (field) => field.id === action.newCustomField.id
    );
    const customFields = [...state.customFields];
    customFields[findIndex] = action.newCustomField;
    return {
      ...state,
      customFields,
    };
  }),
  on(getGlobalFieldsSuccess, (state, action) => ({
    ...state,
    globalFields: action.content,
  })),
  on(createGlobalFieldSuccess, (state, action) => ({
    ...state,
    globalFields: [...state.globalFields, action.globalField],
  })),
  on(deleteGlobalFieldSuccess, (state, action) => ({
    ...state,
    globalFields: state.globalFields.filter((field) => field.id !== action.id),
  })),
  on(updateGlobalFieldSuccess, (state, action) => {
    const findIndex = state.globalFields.findIndex(
      (field) => field.id === action.newGlobalField.id
    );
    const globalFields = [...state.globalFields];
    globalFields[findIndex] = action.newGlobalField;
    return {
      ...state,
      globalFields,
    };
  }),
  on(getPaymentsSuccess, (state, action) => ({
    ...state,
    payments: action.payments,
  })),
  on(deletePaymentSuccess, (state, action) => ({
    ...state,
    payments: state.payments.filter(
      (payment) => !action.ids.includes(payment.id)
    ),
  })),
  on(createPaymentSuccess, (state, action) => ({
    ...state,
    payments: [...state.payments, action.newPayment],
  })),
  on(updatePaymentSuccess, (state, action) => {
    const findIndex = state.payments.findIndex(
      (payment) => payment.id === action.newPayment.id
    );
    const clonePayments = [...state.payments];
    clonePayments[findIndex] = action.newPayment;
    return {
      ...state,
      payments: clonePayments,
    };
  }),
  on(toggleShowStatistics, (state) => ({
    ...state,
    isShowStatistics: !state.isShowStatistics,
  })),
  on(updateTokenChannelSuccess, (state, action) => {
    const findChannelIndex = state.channels.findIndex(
      (channel) => channel.id === action.channel.id
    );
    const cloneChannels = [...state.channels];
    if (findChannelIndex > -1) {
      cloneChannels[findChannelIndex] = action.channel;
    }
    return {
      ...state,
      channels: cloneChannels,
      errorUpdateTokenChannel: null,
      isCloseRefreshTokenDialog: true,
    };
  }),
  on(updateTokenChannelFailed, (state, action) => ({
    ...state,
    errorUpdateTokenChannel: action.error,
  })),
  on(getConnectedIntegrationsSuccess, (state, action) => ({
    ...state,
    connectedIntegrations: action.data,
  })),
  on(toggleSideBar, (state) => ({
    ...state,
    isShowBigSidebar: !state.isShowBigSidebar,
  })),
  on(updateChannelSuccess, (state, { channel }) => {
    const channels = structuredClone(state.channels);
    const findIndex = channels.findIndex(
      (channelItem) => channelItem.id === channel.id
    );
    channels[findIndex] = channel;
    return {
      ...state,
      channels,
    };
  }),
  on(addBroadcast, (state, { broadcast }) => {
    return {
      ...state,
      broadcast,
    };
  }),
  on(getStatisticSuccessAction, (state, { statistic }) => ({
    ...state,
    statistic,
  })),
  on(toggleShowContentAction, (state) => ({
    ...state,
    isShowContent: !state.isShowContent,
  })),
  on(getBusinessConnectionSuccess, (state, { data }) => ({
    ...state,
    businessConnections: data,
  })),
  on(resetStateAction, () => initialUserState)
);

export function appReducer(state: AppState | undefined, action: any) {
  return _appReducer(state, action);
}
