import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { UserInterface } from '../interfaces/user.interface';
import { UpdatePasswordInterface } from '../interfaces/updatePassword.interface';
import { UserInfoInterface } from '../interfaces/user-info.interface';
import { RemoveSavedPaymentMethod } from '../interfaces/remove-saved-payment-method';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _apiUrl = `${env.apiAuth}/api/${env.apiVersion}/user`;
  private _apiUrlUserService = `${env.apiUrl}/api/${env.apiVersion}/user-service`;

  constructor(private _http: HttpClient) {}

  getUserInfo(): Observable<UserService> {
    return this._http.get<UserService>(`${this._apiUrl}/info`);
  }

  userUpdate(user: UserInterface): Observable<any> {
    return this._http.post(`${this._apiUrlUserService}/user`, user);
  }

  deleteUser(): Observable<any> {
    return this._http.delete(`${this._apiUrl}/`);
  }

  updatePassword(updatePasswords: UpdatePasswordInterface): Observable<any> {
    return this._http.post(`${this._apiUrl}/password/update/`, updatePasswords);
  }

  setupPassword(updatePasswords: UpdatePasswordInterface): Observable<any> {
    return this._http.post(`${this._apiUrl}/password/setup/`, updatePasswords);
  }

  getCurrentUserInfo(): Observable<UserInfoInterface> {
    return this._http.get<UserInfoInterface>(`${this._apiUrlUserService}/user`);
  }

  getUserSubscription(): Observable<any> {
    return this._http.get(
      `${this._apiUrlUserService}/user/subscription-migrated`
    );
  }

  removeSavedPaymentMethod(
    data: RemoveSavedPaymentMethod
  ): Observable<UserInfoInterface> {
    return this._http.post<UserInfoInterface>(
      `${env.apiUrl}/api/v2/user-service/user/removeSavedPaymentMethod`,
      data
    );
  }

  removeBillingCompany(): Observable<UserInfoInterface> {
    return this._http.post<UserInfoInterface>(
      `${env.apiUrl}/api/v2/user-service/user/removeBillingCompanyInformation`,
      {}
    );
  }

  generateToken(password: string): Observable<{ token: string }> {
    return this._http.post<{ token: string }>(`${this._apiUrl}/ott/generate`, {
      password,
    });
  }

  validateToken(token: string): Observable<any> {
    return this._http.post(
      `${this._apiUrl}/ott/validate`,
      {},
      { params: { token } }
    );
  }

  changeEmail(email: string, oneTimeToken: string): Observable<any> {
    return this._http.post(
      `${env.apiUrl}/api/${env.apiVersion}/user-service/user/email/changeRequest`,
      {
        email,
        oneTimeToken,
      }
    );
  }

  verifyEmailChange(token: string): Observable<any> {
    return this._http.post(
      `${env.apiUrl}/api/${env.apiVersion}/user-service/user/email/changeRequest/verify`,
      {},
      { params: { token } }
    );
  }
}
