import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModalService } from '../../../../../../core/services/modal.service';
import { Type } from '../t-card/t-card.component';

@Component({
  selector: 'app-universal-modal',
  templateUrl: './universal-modal.component.html',
  styleUrls: ['./universal-modal.component.scss'],
})
export class UniversalModalComponent implements OnInit {
  botName = 'По умолчанию название шаблона';
  channelId = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<UniversalModalComponent>,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.channelId = this.data.channels.length
      ? this.data.channels[0].id
      : null;
  }

  closeModal(type?: Type): void {
    this.matDialogRef.close({
      type,
      ...(type === 'use' && {
        name: this.botName,
        channelId: this.channelId,
      }),
    });
  }
}
