
<div class="dialog dialog-v2 share" *ngIf="data">
  <div class="dialog__header">
    <div class="d-flex justify-content-end">
      <span class="icon icon-close" mat-dialog-close></span>
    </div>
  </div>
  <div class="dialog__content share-content">
    <div class="dialog__image-wrapper"><img src="./assets/images/share-bot.svg" width="200"></div>
    <div class="dialog__title">{{ 'SHARE.TITLE' | translate }}</div>
    <div class="share-content__wrapper">
      Публичная активная ссылка
      <div class="share-content__link" (click)="refreshLink(data.id)" [matTooltip]="'SHARE.ALERT_TEXT' | translate"
        matTooltipPosition="above">
        <span class="bot-link">{{ link }}</span>
        <span class="icon icon-arrow-path"></span>
      </div>
      <div class="line" [style.border-bottom]="'1px solid #D9D9D9'"></div>
      <div class="share-content__toggle-wrapper">
        <span class="bot-script-menu__name">
          {{ 'SHARE.PERMISSION' | translate }}
        </span>
        <mat-slide-toggle class="custom-slide-toggle" color="primary" (ngModelChange)="updateFlow($event)"
          [ngModel]="data.sharedCloningEnabled"></mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="btn btn-sm w-100 btn-primary" (click)="copyLink()">
      {{ 'SHARE.COPY_LINK' | translate }}
    </button>
    <button class="btn btn-sm w-100 btn-tertiary" [mat-dialog-close]="false">
      {{ 'Utils.BUTTONS.NEXT_TIME' | translate }}
    </button>
  </div>
</div>