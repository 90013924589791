import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private _router: Router, private _jwtHelper: JwtHelperService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._isLoggedIn(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._isLoggedIn(state);
  }

  private _isLoggedIn(state: RouterStateSnapshot): boolean {
    if (!this._jwtHelper.isTokenExpired()) {
      return true;
    }

    if (!state.url.startsWith('/entry')) {
      this._router.navigate(['/entry']);
    }
    return false;
  }
}
